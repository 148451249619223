@font-face {
  font-family: 'Roboto Condensed';
  src: url('styles/RobotoCondensed-VariableFont_wght.ttf')  format('truetype')
}


body {
  background: rgb(210, 223, 231);
}

.App {
  /* max-width: 1000px; */
  margin: 0 auto;
  align-content: center;
  max-width: 1120px;
  text-align: center;
  padding: 0 2% 30px 2%;
  margin-bottom: 20px;
}

.popup-bg{
  background-color: rgba(0,0,0,0.7);
  color: white;
  width: 100vw;
  padding: 5px 0;
  text-align: center;
  top: 0px;
  position: sticky;
}
.popup-bg p {
  padding: 0;
  margin: 0;
}

h2{
  font-size: 20px;
  font-weight: 500;
  padding-left: 15px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.search-box, .add-box {
  -webkit-appearance: none;
  padding: 0px;
  line-height: 30px;
  margin: 15px auto 20px;
  width: 20rem;
  font-size: 20px;
  background-color: rgb(210, 223, 231);
  display: flex;
}

.search, .add {
  flex-basis: 100%;
  background-color: #eee;
}

.add-box{
  margin: 10px auto 20px;
}

.cross{
  float: right;
  padding: 5px;
  margin: -30px 0 0;
  color: white;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 24px;
}

.cross-emotion{
  float: right;
  margin: 10px 7px 0;
  padding: 5px;
  color: red;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 24px;
}

.cross:hover{
  color: rgb(233, 74, 177);
}

#edit{
  width: 5rem;
  font-size: 23px;
  flex-basis: 2em;
  text-decoration: none;
  margin-left: 5px;
  border-radius: 5px;
  border: #aaa 1px solid;
  color: black;
  background-color: rgb(231, 210, 223);
  /* transform: rotate(180deg) scaleY(-1); */
}

#edit:hover{
  background-color: rgb(207, 165, 192);
}

@media screen and (min-width: 1057px) {
  .search-box, .add-box, .card2, .emotion-card, #add-emotion {
    width: 62.25rem;
  }
}
@media screen and (max-width: 1056px) {
  .search-box, .add-box, .card2, .emotion-card, #add-emotion {
    width: 41.25rem;
  }
}
@media screen and (max-width: 704px) {
  .search-box, .add-box, .card2, .emotion-card, #add-emotion {
    width: 20.25rem;
  }
}

.card-flex, .card {
  text-align: center;
  margin: 0.5rem;
  border: black solid 1px;
  background-color: #eee;
  padding-bottom: 20px;
}

.card2{
  display: flex;
}

.card2 .card{
  margin: 0.5rem 0;
  flex: 1;
}

.emotion{
  cursor: pointer;
}

.emotion:hover{
  color: rgb(233, 74, 177);
}

.grip{
  top: 20px;
  float: none !important;
  display: block !important;
  width: 30px;
  background-color: #777;
  margin: 0.5rem 0;
  padding: 70px 0;
  border-top: black 1px solid;
  border-left: black 1px solid;
  border-bottom: black 1px solid;
  color: white;
  font-size: 20px;
}

#add-emotion{
  display: flex;
  margin: 0.5rem;
}

.emotion-card, #emotion-input{
  text-align: left;
  margin: 0.5rem;
  border: #aaa solid 1px;
  background-color: #eee;
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
}

#emotion-input{
  padding: 17px 0 17px 15px;
  margin: 0;
  width: calc(100% + 2rem);
}

#add{
  width: 5rem;
  font-size: 23px;
  flex-basis: 2em;
  text-decoration: none;
  margin: 0 0 0 5px;
  border-radius: 5px;
  border: #aaa 1px solid;
  color: black;
  background-color: rgb(231, 210, 223);
  /* transform: rotate(180deg) scaleY(-1); */
}

.card-flex{
  width: 20rem;
}

.card-group-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.card-group{
  display: inline-block;
  margin-top: 0;
}

.category {
  color: white;
  margin: 0;
  padding: 2px 0px;
  font-weight: 600;
}

#D{
  background-color: #800000;
}

#E{
  background-color: #006400;
}

#I{
  background-color: #034168;
}

#M{
  background-color: #2A0134;
}


.when, .what {
  text-align: left;
  padding: 0 15px;
}

.why{
  text-align: left;
  margin: 10px 0;
  padding: 0 15px 0 5px;
}

.what{
  margin-bottom: -10px;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  padding: 45px 0 10px;
  margin: 0;
}